// Generated by Framer (838580a)

import { addFonts, cx, CycleVariantState, getFontsFromSharedStyle, Link, RichText, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/GoYypVzwN";

const cycleOrder = ["N0kfGRN4V"];

const serializationHash = "framer-bMJs7"

const variantClassNames = {N0kfGRN4V: "framer-v-1xtmkpy"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "N0kfGRN4V", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={{hash: ":zNwp3RX6z", webPageId: "augiA20Il"}} openInNewTab={false} smoothScroll><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1xtmkpy", className, classNames)} framer-10yo8sz`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"N0kfGRN4V"} ref={ref ?? ref1} style={{borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-93vyfr"} data-styles-preset={"GoYypVzwN"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-24a77869-6c1b-4d21-aeba-9fb695018ee3, rgb(139, 92, 246)))"}}>Kontaktirajte nas za vise</motion.p></React.Fragment>} className={"framer-tvwc81"} data-framer-name={"Text"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"QDazIwBNt"} style={{"--extracted-r6o4lv": "var(--token-24a77869-6c1b-4d21-aeba-9fb695018ee3, rgb(139, 92, 246))", "--framer-paragraph-spacing": "18px"}} verticalAlignment={"top"} withExternalLayout/><SVG className={"framer-1tygn0v"} data-framer-name={"corner-down-right"} fill={"rgba(0,0,0,1)"} intrinsicHeight={21} intrinsicWidth={20} layoutDependency={layoutDependency} layoutId={"xx94vG0sv"} svg={"<svg width=\"20\" height=\"21\" viewBox=\"0 0 20 21\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M3.33203 3.94336V5.11003C3.33203 7.91029 3.33203 9.31042 3.877 10.38C4.35637 11.3208 5.12127 12.0857 6.06208 12.5651C7.13164 13.11 8.53177 13.11 11.332 13.11H16.6654M16.6654 13.11L12.4987 8.94336M16.6654 13.11L12.4987 17.2767\" stroke=\"#030712\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>\n"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-bMJs7.framer-10yo8sz, .framer-bMJs7 .framer-10yo8sz { display: block; }", ".framer-bMJs7.framer-1xtmkpy { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 6px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; text-decoration: none; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-bMJs7 .framer-tvwc81 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-bMJs7 .framer-1tygn0v { flex: none; height: 21px; position: relative; width: 20px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-bMJs7.framer-1xtmkpy { gap: 0px; } .framer-bMJs7.framer-1xtmkpy > * { margin: 0px; margin-left: calc(6px / 2); margin-right: calc(6px / 2); } .framer-bMJs7.framer-1xtmkpy > :first-child { margin-left: 0px; } .framer-bMJs7.framer-1xtmkpy > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 22
 * @framerIntrinsicWidth 212
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerhAob6_Pvo: React.ComponentType<Props> = withCSS(Component, css, "framer-bMJs7") as typeof Component;
export default FramerhAob6_Pvo;

FramerhAob6_Pvo.displayName = "Link";

FramerhAob6_Pvo.defaultProps = {height: 22, width: 212};

addFonts(FramerhAob6_Pvo, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})